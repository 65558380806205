class App {
  toggleMenus = document.querySelectorAll('.toggle-menu') as NodeListOf<HTMLElement>;
  menu = (document.querySelector('#responsive-menu') as HTMLElement).classList;

	constructor() {
	}

  start() {
    this.navigation()
	this.mediaVideo()
	this.setFloatingButtons()
  }

	navigation() {
		this.toggleMenus.forEach(toggler => {
			toggler.addEventListener('click', () => {
				const timeout = this.menu.contains('open') ? 500 : 0
				setTimeout(() => this.menu.toggle('hidden'), timeout);
				this.menu.toggle('open')
			})
		})
	}

	mediaVideo() {
    const videos = document.querySelectorAll('.media-video') as NodeListOf<HTMLVideoElement>
		videos.forEach((v: HTMLVideoElement) => {
			try {
				v.muted = true
				v.load()
				v.play()
			} catch(e) {
				alert('Cannot play' + v.src + ': ' + e)
				v.controls = true
			}
		})
	}

	setFloatingButtons() {
		document.querySelectorAll('.floating-button')
		.forEach(e => e.addEventListener('click', () => {
			const target = e.getAttribute('data-target')
			if(target) {
				const a = document.createElement('a')
				a.href = atob(target)
				a.target = '_blank'
				a.click()
			}
		}))
    document.querySelectorAll('.ofusqued')
		.forEach(e => e.addEventListener('click', () => {
			const target = e.getAttribute('data-target')
			if(target) {
				const a = document.createElement('a')
				a.href = atob(target)
				a.target = '_blank'
				a.click()
			}
		}))
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const app = new App()
	app.start()

	const main = document.querySelector('main') as HTMLElement
	const script = document.createElement('script')

  let route = ''
  if(main.getAttribute('child')!=null)
    route =  `../assets/js/${main.id}.min.js`
  else
	  route = `assets/js/${main.id}.min.js`

	script.src = route
	script.async = true
	script.type = 'module'
	document.body.appendChild(script)

	script.onerror = () => {
		console.error(`Error loading ${route}`)
	}
})
